import React from "react"
import Seo from "../components/seo"
import Footer from "../components/Footer"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import ComponentSelector from "../components/ComponentSelector"

export default function StrategicImperative({ data, pageContext }) {
  // = = = = = = = = = = = = =
  // Data
  const { slug, title, showFooterForm } = pageContext
  const { components } = data.strategicImperative.nodes[0]

  return (
    <>
      <Layout>
        <Seo
          title={title}
          description={data?.page?.nodes[0].metadata?.description}
          image={data?.page?.nodes[0].metadata?.image}
        />
        <div className="focus-area">
          <ComponentSelector components={components} />
        </div>
        <Footer showFooterForm={showFooterForm} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    strategicImperative: allDatoCmsStrategicImperative(
      filter: { slug: { eq: $slug } }
    ) {
      nodes {
        title
        slug
        id
        showFooterForm
        metadata {
          description
          title
          image {
            url
          }
          twitterCard
        }
        components {
          ... on DatoCmsHeroHeader {
            componentId
            background {
              backgroundColor
              hasBackgroundImage
              image {
                gatsbyImageData
              }
            }
            hasNav
            hasBreadcrumb
            breadcrumb {
              label
              linkUrl
            }
            headline
            heroSize
            innerNav {
              links {
                linkUrl
                label
                isThisAButton
              }
            }
            intro
          }
          ... on DatoCmsFormCtaBanner {
            componentId
            headline
            description
            donationForm
            formHeadline
            formLink
            amountButtons {
              amount
            }
            recurringDonationOptions {
              recurringDonation
            }
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
            rightBackground {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            leftBackground {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on DatoCmsFeaturedNarrative {
            backgroundImage {
              gatsbyImageData
            }
            componentId
            frontImage {
              gatsbyImageData
            }
            textContent
          }
          ... on DatoCmsVideoEmbed {
            componentId
            embedCode
            youtubeOrVimeo
            imageOverlay
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          ... on DatoCmsStatisticsRow {
            componentId
            id
            statistics {
              number
              description
            }
          }
          ... on DatoCmsNarrativeTextBlock {
            componentId
            textContent
            hasImage
            blockWidth
            highlightColor
            imageAlignment
            blockImage {
              featuredImage {
                gatsbyImageData(placeholder: BLURRED)
                title
              }
            }
          }
          ... on DatoCmsFeatureCta {
            componentId
            hasVideo
            headline
            intro
            videoSource
            videoLink
            featureTag
            featuredImage {
              featuredImage {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
            button {
              label
              linkUrl
            }
            background {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`
